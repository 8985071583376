import React from "react";
import PropTypes from "prop-types";
import "./index.scss";

const PanelItem = ({
  title,
  price,
  annualPrice,
  listTitle,
  list,
  select,
  handleItemClick,
}) => {
  return (
    <div
      className={`PgPEU-PanelItem${select ? " PgPEU-PanelItem_select" : ""}`}
      onClick={handleItemClick}
    >
      <div className="PgPEU-PanelItem__dot" />
      <div className="PgPEU-PanelItem-info">
        <p className="PgPEU-PanelItem-info__title">{title}</p>
        <p className="PgPEU-PanelItem-info__price">{price}</p>
        {annualPrice ? (
          <p className="PgPEU-PanelItem-info__year">{annualPrice}</p>
        ) : null}
      </div>
      <div className="PgPEU-PanelItem-description">
        <p className="PgPEU-PanelItem-description__title">{listTitle}</p>
        <ul className="PgPEU-PanelItem-list">
          {list.map((l) => {
            return (
              <li
                key={new Date().getTime() * Math.floor(Math.random() * 999)}
                className="PgPEU-PanelItem-list__item"
              >
                {l}
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

PanelItem.propTypes = {
  title: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  annualPrice: PropTypes.string,
  select: PropTypes.bool,
  listTitle: PropTypes.string.isRequired,
  list: PropTypes.array.isRequired,
  handleItemClick: PropTypes.func.isRequired,
};

export default PanelItem;
