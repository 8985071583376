import React from "react";
import PropTypes from "prop-types";
import SCGDarkLogo from "../../../../assets/images/vector/components/common/dark.svg";
import ExtensionUpgradeList from "./InfoList";
import PG_PEU_INFO_CONTENT from "./index.content";
import "./index.scss";

const Info = ({ variant }) => {
  return (
    <div className="PgPEU-Info">
      <img className="PgPEU-Info__logo" src={SCGDarkLogo} alt="logo" />
      <p className={`PgPEU-Info__title PgPEU-Info__title_${variant}`}>
        {PG_PEU_INFO_CONTENT.mainTitle[variant]}
      </p>
      <ExtensionUpgradeList
        title={PG_PEU_INFO_CONTENT.list.title[variant]}
        items={PG_PEU_INFO_CONTENT.list.items[variant]}
        customClass={`ExtensionUpgradeList_${variant}`}
      />
      <p
        className={`PgPEU-Info__description PgPEU-Info__description_${variant}`}
      >
        {PG_PEU_INFO_CONTENT.description}
      </p>
      <div className="PgPEU-Info-control">
        <a
          className="PgPEU-Info-control__btn"
          href={`${process.env.MAIN_HOST}/?help=true`}
          target="_blank"
        >
          Need assistance?
        </a>
        <a
          className="PgPEU-Info-control__btn"
          href={`${process.env.MAIN_HOST}/terms-of-use/`}
          target="_blank"
        >
          Terms of Use
        </a>
      </div>
    </div>
  );
};

Info.propTypes = {
  variant: PropTypes.string.isRequired,
};

export default Info;
