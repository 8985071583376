import track from "../../tracking/analytics";

class LoadingTimeController {
  constructor() {
    this.url = new URL(window.location.href);
    this.path = this.url.pathname;
    this.params = this.url.searchParams;
    this.software = this.params.get("software") || "LANDING";
    this.label = document.referrer.split("?")[0];
    this.sendViewWithPageSpeedInValue();
  }

  sendViewWithPageSpeedInValue() {
    if (window.performance.timing.loadEventEnd === 0) {
      return window.setTimeout(() => this.sendViewWithPageSpeedInValue(), 500);
    } else if (!sessionStorage.getItem(this.path) && !window.previousPath) {
      const time =
        window.performance.timing.loadEventEnd -
        window.performance.timing.navigationStart;
      sessionStorage.setItem(this.path, time);
      track("view", this.path, this.label, time, this.software);
    }
  }
}

export default LoadingTimeController;
