import React, { useEffect } from "react";
import useFingerPrint from "../../hooks/useFingerPrint";
import PropTypes from "prop-types";
import TagManager from "react-gtm-module";
import LoadingTimeController from "../../controllers/common/loadingTimeController";
import "../../assets/scss/UI/index.scss";
import "../../assets/scss/fonts/fonts.scss";
import "../../assets/scss/index.scss";

const tagManagerArgs = {
  gtmId: process.env.GTM_ID,
};

const Layout = (props) => {
  const { className, children, withTagManager = true, turnOnEventView } = props;
  useEffect(() => {
    withTagManager && TagManager.initialize(tagManagerArgs);
    turnOnEventView && new LoadingTimeController();
  }, []);

  useFingerPrint(false);

  return (
    <>
      <main className={className}>{children}</main>
    </>
  );
};

Layout.protoTypes = {
  children: PropTypes.node.isRequired,
  class: PropTypes.string,
  withTagManager: PropTypes.bool,
  turnOnEventView: PropTypes.bool,
};

export default Layout;
