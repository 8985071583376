import React from "react"

const PG_PEU_PANEL_CONTENT = {
  main: {
    title: {
      a: "Select a Plan and enjoy PRO Extension on up to 20 devices",
      b: "Select a Plan and enjoy PRO Extension up to 10 000 launches",
      c: "Select a Plan and enjoy PRO Extension up to 10 000 launches",
    },
    btn: "Upgrade your pricing plan now",
    periods: ["Monthly", "Yearly"],
  },
  list: {
    title: "Full AMZscout toolset",
    premium: [
      <>
        <b>10</b> devices with <b>6,000</b> PRO Extension launches/month
      </>,
      "Keywords Tracker with 10,000 words limit",
      "Product Tracker with 500 items",
    ],
    ultimate: [
      <>
        <b>20</b> devices with <b>20,000</b> PRO Extension launches/month
      </>,
      "Keywords Tracker with 10,000 words limit",
      "Product Tracker with 500 items",
    ],
  },
  pricing: {
    all: ["premium", "ultimate"],
    premium: {
      title: "Premium",
      yearly: "$83.25 /month",
      monthly: "$149 /month",
      annual: "$999 billed annually",
    },
    ultimate: {
      title: "Ultimate",
      yearly: "$149.9 /month",
      monthly: "$249 /month",
      annual: "$1,799 billed annually",
    },
  },
}

export default PG_PEU_PANEL_CONTENT
