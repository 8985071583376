import React, { useState } from "react";
import Panel from "../../../components/pw/extension-upgrade/Panel";
import PG_PEU_PANEL_CONTENT from "../../../components/pw/extension-upgrade/Panel/index.content";
import PropTypes from "prop-types";
import SubmitController from "../../../controllers/pw/extension-upgrade/submitController";

const PanelContainer = ({ variant }) => {
  const [getPanelState, setPanelState] = useState({
    period: PG_PEU_PANEL_CONTENT.main.periods[0].toLowerCase(),
    select: PG_PEU_PANEL_CONTENT.pricing.all[0],
  });

  const handleSubmit = () => {
    const submitController = new SubmitController(
      `${getPanelState.select}_${getPanelState.period}`
    );
    submitController.handleRedirect();
  };

  const updatePanelState = (state, newValue) => {
    setPanelState((prevState) => {
      return {
        ...prevState,
        [state]: newValue,
      };
    });
  };

  const handlePeriodSwitch = (newPeriod) => {
    return updatePanelState("period", newPeriod);
  };

  const handleSelectSwitch = (newSelect) => {
    return updatePanelState("select", newSelect);
  };

  return (
    <Panel
      variant={variant}
      period={getPanelState.period}
      select={getPanelState.select}
      onPeriodClick={handlePeriodSwitch}
      onSelectClick={handleSelectSwitch}
      content={PG_PEU_PANEL_CONTENT}
      onSubmit={handleSubmit}
    />
  );
};

PanelContainer.propTypes = {
  variant: PropTypes.string.isRequired,
};

export default PanelContainer;
