import React, { useState } from "react";
import Layout from "../../layouts/LayoutIframe";
import Seo from "../../components/Seo";
import OgImg from "../../assets/images/raster/components/common/mainscreen.png";
import "../../components/pw/extension-upgrade/index.scss";
import Info from "../../components/pw/extension-upgrade/Info";
import PanelContainer from "../../containers/pw/extension-upgrade/PanelContainer";

const ExtensionUpgrade = () => {
  const getCurrentVariant = () => {
    if (typeof window !== "undefined") {
      const urlParams = new URLSearchParams(window.location.search);
      return urlParams.get("v");
    }
  };

  const [getVariantState] = useState(getCurrentVariant() || "a");

  return (
    <Layout>
      <Seo
        title="Amazon Product Research Tool & Niche Finder | AMZScout"
        description="Make Amazon product research quick and easy with accurate research software from AMZScout. Analyze niches and find the right products to help you make money."
        page=""
        manifest="browserconfig-index.xml"
        ogImg={OgImg}
      />
      <div className="PgPEU">
        <Info variant={getVariantState} />
        <PanelContainer variant={getVariantState} />
      </div>
    </Layout>
  );
};

export default ExtensionUpgrade;
