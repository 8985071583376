import React from "react"

const PG_PEU_INFO_CONTENT = {
  mainTitle: {
    a: "This is your 4th device with PRO Extension and your current plan only allows up to 3 devices. You can uninstall one of your active copies or upgrade to Premium or Ultimate Plan to use PRO Extension on this device.",
    b: "You’ve launched PRO Extension almost 600 times in 30 days. You may soon exceed the 600 launch limit included in your pricing plan.",
    c: "You’ve launched PRO Extension almost 600 times in 30 days.",
  },
  list: {
    title: {
      a: "Expand your product research capability with AMZScout!",
      b: "Upgrade to Premium or Ultimate Plan to expand your product research capability now!",
      c: "Upgrade to Premium or Ultimate Plan to expand your product research capability!",
    },
    items: {
      a: [
        <>
          Equip your team with PRO Extension on up to <b>20 devices</b> to find
          more profitable products relying on Amazon stats such as sales volume,
          sales per review and more.
        </>,
        <>
          Track <b>10,000 Keywords!</b> Make sure customers can easily find your
          product. Only spend ad budget on keywords that need rank boost to
          bring you sales.
        </>,
        <>
          Monitor <b>500 products without a time limit</b> to know your
          competitors performance daily and evaluate product opportunities over
          time
        </>,
      ],
      b: [
        <>
          Launch PRO Extension up to <b>10,000 times per month</b> to find more
          profitable products relying on Amazon stats such as sales volume,
          sales per review and more.
        </>,
        <>
          Install PRO Extension on up to <b>20 devices</b> to equip all
          employees who do product research.
        </>,
        <>
          Track <b>10,000 Keywords!</b> Make sure customers can easily find your
          product. Only spend ad budget on keywords that need rank boost to
          bring you sales.
        </>,
        <>
          Monitor <b>500 products without a time limit</b> to know your
          competitors performance daily and evaluate product opportunities over
          time.
        </>,
      ],
      c: [
        <>
          Launch PRO Extension up to <b>10,000 times per month</b> to find more
          profitable products relying on Amazon stats such as sales volume,
          sales per review and more.
        </>,
        <>
          Install PRO Extension on up to <b>20 devices</b> to equip all
          employees who do product research.
        </>,
        <>
          Track <b>10,000 Keywords!</b> Make sure customers can easily find your
          product. Only spend ad budget on keywords that need rank boost to
          bring you sales.
        </>,
        <>
          Monitor <b>500 products without a time limit</b> to know your
          competitors performance daily and evaluate product opportunities over
          time.
        </>,
      ],
    },
  },
  description:
    "With these plans, you will also continue to enjoy full access to other AMZScout tools, Monthly Trend Reports, and Weekly top 1% profitable products as well as Masterclasses and Exclusive Trainings.",
}

export default PG_PEU_INFO_CONTENT
