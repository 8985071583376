import React from "react";
import "./index.scss";
import PanelItem from "./PanelItem";
import PropTypes from "prop-types";

const Panel = ({
  variant,
  period,
  select,
  onPeriodClick,
  onSelectClick,
  content,
  onSubmit,
}) => {
  return (
    <div className="PgPEU-Panel">
      <div className="PgPEU-Panel-container">
        <p className="PgPEU-Panel__title">{content.main.title[variant]}</p>
        <div className="PgPEU-Control">
          <div className="PgPEU-Control-switcher">
            {content.main.periods.map((p) => {
              const pLower = p.toLowerCase();
              return (
                <button
                  onClick={() => onPeriodClick(pLower)}
                  className={`PgPEU-Control-switcher__button${
                    period === pLower
                      ? " PgPEU-Control-switcher__button_select"
                      : ""
                  }`}
                  key={new Date().getTime() * Math.floor(Math.random() * 999)}
                >
                  {p}
                </button>
              );
            })}
          </div>
          <div className="PgPEU-Control-items">
            {content.pricing.all.map((tariff) => {
              return (
                <PanelItem
                  title={content.pricing[tariff].title}
                  price={content.pricing[tariff][period]}
                  annualPrice={
                    period === "yearly" ? content.pricing[tariff].annual : null
                  }
                  listTitle={content.list.title}
                  list={content.list[tariff]}
                  select={select === tariff}
                  handleItemClick={() => onSelectClick(tariff)}
                  key={new Date().getTime() * Math.floor(Math.random() * 999)}
                />
              );
            })}
          </div>
          <button className="PgPEU-Panel__button" onClick={onSubmit}>
            {content.main.btn}
          </button>
        </div>
      </div>
    </div>
  );
};

Panel.propTypes = {
  variant: PropTypes.string.isRequired,
  period: PropTypes.string.isRequired,
  select: PropTypes.string.isRequired,
  onPeriodClick: PropTypes.func.isRequired,
  onSelectClick: PropTypes.func.isRequired,
  content: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default Panel;
