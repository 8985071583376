import React from "react";
import PropTypes from "prop-types";
import "./index.scss";

const InfoList = ({ title, items, customClass }) => {
  return (
    <div className={`PgPEU-InfoList${customClass ? " " + customClass : ""}`}>
      <p className="PgPEU-InfoList__title">{title}</p>
      <ul className="PgPEU-InfoList-list">
        {items.map((e) => {
          return (
            <li
              key={new Date().getTime() * Math.floor(Math.random() * 999)}
              className="PgPEU-InfoList-list__item"
            >
              {e}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

InfoList.propTypes = {
  title: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
  customClass: PropTypes.string,
};

export default InfoList;
