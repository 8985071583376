class SubmitController {
  constructor(newTariff) {
    this.params = new URL(window.location.href).searchParams;
    this.software = this.params.get("software");
    this.url = this.params.get("url");
    this.tariff = this.params.get("t");
    this.period = this.params.get("p");
    this.licenseId = this.params.get("l");
    this.hash = this.params.get("h");
    this.newTariffPlan = btoa(newTariff);
  }

  checkParams() {
    return (
      !this.tariff ||
      !this.period ||
      !this.licenseId ||
      !this.hash ||
      !this.newTariffPlan ||
      !this.software
    );
  }

  handleRedirect() {
    if (!this.checkParams()) {
      window.top.location.href = `/request-upgrade?${
        window.location.href.split("?")[1]
      }&n=${encodeURIComponent(this.newTariffPlan)}`;
    } else {
      alert("One of the required parameters is missing!");
    }
  }
}

export default SubmitController;
